export const normalizeApiUrl = (url = '') => {
  if (url === '.' || url === '/' || !url) {
    return '';
  }
  return new URL(url).origin;
};

export function makeOffsetTimeZoneString() {
  let off = new Date().getTimezoneOffset();
  let tz = window.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';
  return `${off},${tz}`;
}

export const debounce = (func, delay) => {
  let timeout = null;
  return (...rest) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...rest);
        timeout = null;
      }, delay);
    } else
      timeout = setTimeout(() => {
        func(...rest);
        timeout = null;
      }, delay);
  };
};

export const getUserDisplayName = (user, type) => {
  if (user) {
    if (user.firstName && user.lastName) {
      return type === 'avatar'
        ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
        : `${user.firstName} ${user.lastName}`;
    } else if (user.lastName) {
      return type === 'avatar' ? user.lastName[0].toUpperCase() : user.lastName;
    } else if (user.firstName) {
      return type === 'avatar' ? user.firstName[0].toUpperCase() : user.firstName;
    }
    return type === 'avatar' ? user.email[0].toUpperCase() : user.email;
  }
};
